<template>
  <Loader
    v-if="isLoadingData"
    class="my-auto">
  </Loader>

  <div v-else>
    <form
      @submit.prevent="handleIdentitySubmit"
      :class="[
        'artist-edit-identity-form__wrapper',
        { 'artist-edit-identity-form__wrapper--artist': isArtist },
      ]"
      ref="form"
      id="artist-edit-identity-form"
      novalidate>
      <Select
        v-model="genderOption"
        :options="GENDERS_OPTIONS"
        :label="`${$t('common.gender')}*`"
        isAutofocus
        id="identity-gender-select"
        required
        class="artist-edit-identity-form__gender">
      </Select>
      <FieldInput
        v-model="firstName"
        :label="`${$t('common.firstName')}*`"
        :placeholder="$t('common.firstName')"
        :bordersPositions="['top', 'left', 'right']"
        required
        id="identity-first"
        type="text"
        class="artist-edit-identity-form__firstname">
      </FieldInput>
      <FieldInput
        v-model="lastName"
        :label="`${$t('common.lastName')}*`"
        :placeholder="$t('common.lastName')"
        :bordersPositions="['top', 'right']"
        required
        id="identity-lastname"
        type="text"
        class="artist-edit-identity-form__lastname">
      </FieldInput>
      <FieldInput
        v-if="isArtist"
        v-model="artistNickname"
        :label="$t('common.nickname')"
        :placeholder="$t('common.nickname')"
        id="identity-nickname"
        type="text"
        class="artist-edit-identity-form__nickname">
      </FieldInput>
      <fieldset
        form="artist-edit-identity-form"
        name="birthday"
        class="artist-edit-identity-form__birthday-wrapper">
        <label
          for="birthday-input-0"
          class="field-input__label">
          {{
            isArtist
              ? `${$t('common.birthday')}*`
              : $t('common.birthday')
          }}
        </label>
        <FieldInput
          v-for="(birthdayInput, index) in birthdayInputs"
          v-model="birthday[birthdayInput.type]"
          @maxlength="handleFocusNextInput(birthdayInputs[index + 1])"
          :placeholder="birthdayInput.placeholder"
          :id="`birthday-input-${index}`"
          :key="`birthday-input-${index}`"
          :minNumberValue="birthdayInput.min"
          :maxNumberValue="birthdayInput.max"
          :minlength="birthdayInput.minlength"
          :maxlength="birthdayInput.maxlength"
          :isLiveValidation="false"
          :ref="`birthday-${birthdayInput.type}`"
          :required="isArtist"
          type="tel">
        </FieldInput>
        <span
          v-if="birthdayErrors.length"
          :class="[
            'field-input__error-message',
            { [`field-input__error-message--${birthdayErrors[0].type}`]: true },
          ]"
          style="grid-column: 1 / -1;">
          {{ birthdayErrors[0].message }}
        </span>
      </fieldset>
      <GoogleLocationFinder
        @input="handleAddressInput"
        @place-selected="handleSetAddress"
        :prefilledText="adress"
        :options="{ types: ['(regions)'] }"
        :label="`${$t('common.address')}*`"
        :placeholder="`${$t('common.address')}*`"
        :errors="googleLocationErrors"
        isRequired
        class="artist-edit-identity-form__line-one">
      </GoogleLocationFinder>
      <FieldInput
        v-model="adressAdditional"
        :label="$t('common.addressComplement')"
        :placeholder="$t('common.addressComplement')"
        :bordersPositions="['left', 'right']"
        type="text"
        id="adress-additional"
        class="artist-edit-identity-form__line-two">
      </FieldInput>
      <FieldInput
        v-model="city"
        :label="`${$t('common.city')}*`"
        :placeholder="`${$t('common.city')}*`"
        type="text"
        required
        id="city"
        class="artist-edit-identity-form__city">
      </FieldInput>
      <FieldInput
        v-model="postalCode"
        :label="`${$t('common.zipCode')}*`"
        :placeholder="`${$t('common.zipCode')}*`"
        :bordersPositions="['left', 'right']"
        type="text"
        minlength="4"
        maxlength="5"
        pattern="[0-9]{4,5}"
        required
        id="postal-code"
        class="artist-edit-identity-form__postal-code">
      </FieldInput>
      <Select
        v-model="country"
        :options="countriesList"
        :label="`${$t('common.country')}*`"
        :placeholder="`${$t('common.country')}*`"
        isReadOnly
        required
        id="country"
        class="artist-edit-identity-form__country">
      </Select>

      <Loader
        v-if="isLoading"
        :height="''"
        class="artist-edit-identity-form__loader">
      </Loader>
      <Button
        v-else
        :isFluid="!$mediaQueries.isDesktop"
        type="submit"
        class="artist-edit-identity-form__submit">
        {{ $t("common.save") }}
      </Button>
    </form>

    <Paragraph :size="'sm'">
      *{{ $t('common.mandatoryField') }}
    </Paragraph>
  </div>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
}                                     from 'vuex';

import FieldInput                     from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button                         from '../../../components/atoms/Button/a-Button.vue';
import Paragraph                      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Select                         from '../../../components/atoms/Select/a-Select.vue';
import Loader                         from '../../../components/atoms/Loader/a-Loader.vue';
import GoogleLocationFinder           from '../../../components/molecules/GoogleLocationFinder/m-GoogleLocationFinder.vue';
import countriesList                  from '../../../constants/countriesList.js';
import GENDERS_OPTIONS                from '../../../constants/gender-options.js';
import BIRTHDAY_INPUTS                from '../../../constants/birthday-inputs.js';
import {
  getDateFromDDMMYYYYToMMDDYYYY,
  isValidBirthday,
}                                     from '../../../utils/dateUtils.js';


export default {
  name: 'o-EditIdentityForm',
  components: {
    FieldInput,
    Button,
    Paragraph,
    Select,
    Loader,
    GoogleLocationFinder,
  },
  data: () => ({
    birthdayInputs: BIRTHDAY_INPUTS,
    countriesList,
    googleLocationErrors: [],
    birthdayErrors: [],
    isLoadingData: false,
    isLoading: false,
    hasModifiedAddress: false,
    birthday: {
      day: '',
      month: '',
      year: '',
    },
    genderOption: '',
    firstName: '',
    lastName: '',
    artistNickname: '',
    adress: '',
    adressAdditional: '',
    postalCode: '',
    city: '',
    country: 'FR',
    GENDERS_OPTIONS,
    longitude: '',
    latitude: '',
  }),
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters({ isArtist: 'User/IS_ARTIST_USER' }),
  },
  async created() {
    try {
      this.isLoadingData = true;

      await this.fetchMe();

      this.prefillFields();
    } finally {
      this.isLoadingData = false;
    }
  },
  watch: {
    birthday: {
      deep: true,
      handler() {
        const hasFilledBirthdayInput = Object.values(this.birthday).every(date => date && date !== '0');

        if (!hasFilledBirthdayInput) return;

        const isValidBirthday = this.isValidBirthday({
          day: this.birthday.day,
          month: this.birthday.month,
          year: this.birthday.year
        });

        if (!isValidBirthday) return this.birthdayErrors.push({ type: 'alert', message: this.$t('artist.identity.errors.invalidBirthday') });
        else this.birthdayErrors = [];
      },
    },
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
    }),
    ...mapActions({
      editIdentity: 'User/EDIT_IDENTITY',
      fetchMe: 'User/FETCH_ME',
    }),
    isValidBirthday,
    prefillFields() {
      if (this.user.Birthday) {
        const birthday = new Date(getDateFromDDMMYYYYToMMDDYYYY(this.user.Birthday));

        this.birthday.day = birthday.toLocaleDateString('fr', { day: '2-digit' });
        this.birthday.month = birthday.toLocaleDateString('fr', { month: '2-digit' });
        this.birthday.year = birthday.getFullYear();
      }

      this.genderOption = this.user?.Gender ?? '';
      this.firstName = this.user?.Firstname ?? '';
      this.lastName = this.user?.Lastname ?? '';
      this.artistNickname = this.user?.ArtisteNickname ?? '';
      this.adress = this.user?.Line1 ?? '';
      this.adressAdditional = this.user?.Line2 ?? '';
      this.postalCode = this.user?.PostalCode ?? '';
      this.city = this.user?.City ?? '';
      // this.country = this.user?.Country ?? '';
      this.latitude = this.user?.Latitude ?? '';
      this.longitude = this.user?.Longitude ?? '';
    },
    handleAddressInput() {
      this.hasModifiedAddress = true;
      this.latitude = '';
      this.longitude = '';
    },
    handleSetAddress(place) {
      const { address_components = [] } = place;
      const streetNumber = address_components?.find(({ types }) => types.includes('street_number'))?.long_name;
      const adress = address_components?.find(({ types }) => types.includes('route'))?.long_name;
      const postalCode = address_components?.find(({ types }) => types.includes('postal_code'))?.long_name;
      // const country = address_components?.find(({ types }) => types.includes('country'))?.short_name;
      const city = address_components?.find(({ types }) => types.includes('locality') || types.includes('postal_town'))?.long_name;

      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
      this.adress = adress ? `${streetNumber ? `${streetNumber} ` : ''}${adress}` : '';
      this.city = city ?? '';
      this.postalCode = postalCode ?? '';
      // this.country = country ?? '';
      this.googleLocationErrors = [];
      this.hasModifiedAddress = false;
    },
    async handleIdentitySubmit() {
      const isValidForm = this.$refs.form.checkValidity();
      const hasSetupLocationCoordinates = !this.hasModifiedAddress && !!this.latitude && !!this.longitude;
      const hasFilledBirthdayInput = Object.values(this.birthday).every(date => date && date !== '0');

      const isValidBirthday = this.isValidBirthday({
        day: this.birthday.day,
        month: this.birthday.month,
        year: this.birthday.year,
      });

      if (!hasFilledBirthdayInput && this.isArtist) return;

      if (!isValidBirthday && this.isArtist) return this.birthdayErrors.push({ type: 'alert', message: this.$t('artist.identity.errors.invalidBirthday') });
      else this.birthdayErrors = [];

      if (!hasSetupLocationCoordinates) return this.googleLocationErrors.push({ type: 'alert', message: this.$t('artist.identity.errors.noCitySelected') });
      else this.googleLocationErrors = [];

      if (!isValidForm) return;

      this.isLoading = true;

      const params = {
        input: {
          Firstname: this.firstName,
          Lastname: this.lastName,
          Gender: this.genderOption,
          Birthday: {
            DOBDay: Number(this.birthday.day),
            DOBMonth: Number(this.birthday.month),
            DOBYear: Number(this.birthday.year),
          },
          Location: {
            Line1: this.adress,
            Line2: this.adressAdditional,
            PostalCode: this.postalCode.toString(),
            City: this.city,
            Country: this.country,
            Longitude: this.longitude,
            Latitude: this.latitude,
          },
          ...(this.isArtist ? { ArtisteNickname: this.artistNickname } : {}),
        },
      };

      try {
        const isCompleted = await this.editIdentity(params);

        if (isCompleted) this.$emit('success');
      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
        this.$emit('error');
      } finally {
        this.isLoading = false;
      }
    },
    handleFocusNextInput(nextInput) {
      this.$refs?.[`birthday-${nextInput?.type}`]?.[0]?.$refs?.input?.focus();
    },
  },
}

</script>

<style lang="scss">

.artist-edit-identity-form {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7, auto);
    grid-gap: var(--space-base);
    margin-bottom: var(--space-xl);
    grid-template-areas:
      "gender gender"
      "firstname lastname"
      "nickname nickname"
      "birthday birthday"
      "line-one line-one"
      "line-two line-two"
      "city postal-code"
      "country country"
      "submit submit"
    ;

    @media screen and ($desktop) {
      grid-gap: var(--space-base) var(--space-lg);
      grid-template-areas:
        "gender ."
        "firstname lastname"
        "birthday birthday"
        "line-one line-one"
        "line-two line-two"
        "city postal-code"
        "country ."
        "submit ."
      ;

      &--artist {
        grid-template-areas:
          "gender ."
          "firstname lastname"
          "nickname birthday"
          "line-one line-one"
          "line-two line-two"
          "city postal-code"
          "country ."
          "submit ."
        ;
      }
    }
  }

  &__gender {
    grid-area: gender;
  }

  &__firstname {
    grid-area: firstname;
  }

  &__lastname {
    grid-area: lastname;
  }

  &__nickname {
    grid-area: nickname;
  }

  &__line-one {
    grid-area: line-one;
  }

  &__line-two {
    grid-area: line-two;
  }

  &__city {
    grid-area: city;
  }

  &__postal-code {
    grid-area: postal-code;
  }

  &__country {
    grid-area: country;
  }

  &__birthday-wrapper {
    grid-area: birthday;
    display: grid;
    grid-template-columns: repeat(3, minmax(50px, 1fr));
    grid-column-gap: var(--space-sm);
  }

  &__submit {
    grid-area: submit;
    margin-top: var(--space-base);
    margin-right: auto;
  }

  &__loader {
    grid-row: -1;
    margin-right: auto;
  }
}

</style>
